.glow-border {
    border: 1px solid red;
    box-shadow: 0 0 4px red, 0 0 4px red, 0 0 4px red, 0 0 4px red;
    animation: glow 1s infinite;
  }
  
  @keyframes glow {
    0% {
      box-shadow: 0 0 4px red, 0 0 4px red, 0 0 4px red, 0 0 4px red;
    }
    50% {
      box-shadow: 0 0 14px red, 0 0 9px orange, 0 0 4px yellow, 0 0 9px orange;
    }
    100% {
      box-shadow: 0 0 4px red, 0 0 4px red, 0 0 4px red, 0 0 4px red;
    }
  }


  #flicker {
    animation: flicker 2s linear infinite;
  }
  
  @keyframes flicker {
    0%, 20%, 100% {
      color: red;
    }
    25%, 45% {
      color: orange;
    }
    50%, 70% {
      color: yellow;
    }
    75%, 95% {
      color: green;
    }
  }

  #second-screen-container table * {
    font-size: 22px;
  }