/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #1b1b1b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #404040;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  